import OverlayCoupon from "../OverlayCoupon/OverlayCoupon";
import OverlayFilter from "../OverlayFilter/OverlayFilter";
import OverlayPOI from "../OverlayPOI/OverlayPOI";

const OverlayController = ({
    overlayContent,
    startZoomOut,
    clearOverlayContent,
    muted,
    playBackgroundAudio,
    pauseBackgroundAudio
}) => {
    const { type } = overlayContent.overlayContent;

    if (type === 'filter') return (
        <OverlayFilter
            overlayContent={overlayContent}
            startZoomOut={startZoomOut}
            clearOverlayContent={clearOverlayContent}
        />
    )

    if (type === 'video') return (
        <OverlayPOI
            overlayContent={overlayContent}
            startZoomOut={startZoomOut}
            clearOverlayContent={clearOverlayContent}
            muted={muted}
            playBackgroundAudio={playBackgroundAudio}
            pauseBackgroundAudio={pauseBackgroundAudio}
        />
    )

    if (type === 'coupon') return (
        <OverlayCoupon
            overlayContent={overlayContent}
            startZoomOut={startZoomOut}
            clearOverlayContent={clearOverlayContent}
        />
    )
};

export default OverlayController;