import { useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { MapControls, useScroll } from '@react-three/drei';
import { Vector3 } from 'three';
import { clamp, distanceBetweenCoords } from '../../utilities/functions';
import { defaultHeight } from '../../data/pointsData';

const Controller = props => {
    const {
        zoomIn,
        zoomOut,
        focus,
        targetOrigin,
        showInstructions,
        showSplashscreen,
        focusPos = new Vector3()        
    } = props;
    
    // Init component variables
    // Touch Vars
    const [ touch, setTouch ] = useState(false);
    const touchOffset = useRef(0);
    const touchIncrement = 0.025;

    // Controller Vars
    const controllerRef = useRef();
    const { camera } = useThree();
    const scroll = useScroll();

    // Clamp controller X, Y, Z values 
    const minPan = new Vector3( -11, defaultHeight, -8 );
    const maxPan = new Vector3( 11, 20, 17 );
    
    // Initilise Camera
    useEffect(() => {
        controllerRef.current.target.set(
            -2, 
            controllerRef.current.target.y,
            10
        );
    }, []);

    // Touch Zoom Controls
    useEffect(() => {
        let prevDistance = 0;

        const handleTouchStart = e => {
            if (zoomIn || zoomOut) return;

            if (e.touches.length === 2) {
                controllerRef.current.enabled = false;
                setTouch(true);
            }
        }

        const handleTouchEnd = () => {
            controllerRef.current.enabled = true;
            prevDistance = 0;
        }

        const handleTouchMove = e => {
            if ( zoomIn || zoomOut || showInstructions || showSplashscreen ) return;
            
            const { touches } = e;

            if (touches.length === 2) {
                const distance = distanceBetweenCoords(touches[0], touches[1]);
                if (distance > prevDistance) {
                    touchOffset.current = clamp((touchOffset.current - touchIncrement), 0, 1);
                    prevDistance = distance;
                }
                else if (distance < prevDistance) {
                    touchOffset.current = clamp((touchOffset.current + touchIncrement), 0, 1);
                    prevDistance = distance;
                }            
            }
        }

        const canvasEl = document.getElementById('canvas');
        canvasEl.addEventListener('touchstart', handleTouchStart, false);
        canvasEl.addEventListener('touchend', handleTouchEnd, false);
        canvasEl.addEventListener('touchmove', handleTouchMove, false);

        return () => {
            canvasEl.removeEventListener('touchstart', handleTouchStart);
            canvasEl.removeEventListener('touchend', handleTouchEnd);
            canvasEl.removeEventListener('touchmove', handleTouchMove);
        }
    }, [ zoomIn, zoomOut, showInstructions, showSplashscreen ])
    
    // Target Restrictions + All Zoom Controls
    useFrame(() => {
        const { target } = controllerRef.current;

        const offset = touch ? touchOffset.current : scroll.offset;
        
        if (!zoomIn && !zoomOut) {
            // Lock target to area and height
            target.clamp( minPan, maxPan );
            target.y = (defaultHeight + 5) + (offset * 17);
        }
       
        // Lock Camera Position + view to target 
        camera.position.set(
            target.x, 
            target.y,
            target.z + 5
            );
        
        camera.lookAt( 
            target.x, 
            target.y / 2,
            target.z 
        );
    });

    // Handle Camera Zoom in / out on Click.
    useFrame(() => {
        if (zoomIn || zoomOut) {
            const { target } = controllerRef.current;       
            zoomIn ? 
                focusPos.set(focus.x, focus.y, focus.z) : 
                focusPos.set(targetOrigin.x, targetOrigin.y, targetOrigin.z);

            target.lerp(focusPos, 0.05);
        }
    })

    return (
        <MapControls
            ref={controllerRef}
            enableRotate={false}
            enableZoom={zoomIn || zoomOut}
            enablePan={!zoomIn && !zoomOut}
            panSpeed={5}
        />
    );
};

export default Controller;