import './GDPRModal.scss';
import CloseIcon from '../../assets/icons/close-button.svg';

const GDPRModal = ({ setShowPrivacyPolicy, dispatch }) => {

    const handleAccept = () => {
        dispatch({ type: "acceptCurrent" });
        setShowPrivacyPolicy(false);
    }

    const handleReject = () => {
        dispatch({ type: "declineAll" });
        setShowPrivacyPolicy(false);
    }

    return (
        <aside className="privacy-policy">
            <header className="privacy-policy__header">
                <button className='privacy-policy__close-modal' onClick={() => setShowPrivacyPolicy(false)}>
                    <img src={CloseIcon} alt="close modal"/>
                </button>
            </header>
            <main className="privacy-policy__content">
                <div>
                    <h1 className="privacy-policy__title">Cookie Policy</h1>
                    <p className='privacy-policy__text'>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
                    <p className='privacy-policy__text'>We use necessary cookies in order to remember your cookies preferences regarding across visits.</p>
                    <p className='privacy-policy__text'>We use analytics and statistics cookies to collect information that is used either in aggregate form to help us understand how this site is being used or how effective marketing campaigns are. These are implemented via Google Analytics '_ga' cookies, which do not track personal information.</p>
                    <p className='privacy-policy__text'>In order to opt-out of non-necessary cookies, click the 'Reject Cookies' option on this page.</p>
                </div>
                <div className="privacy-policy__controller">
                    <button
                        className='privacy-policy__btn'
                        onClick={handleAccept}
                        type="button"
                    >Accept Cookies</button>
                    <button
                        className='privacy-policy__btn'
                        onClick={handleReject}
                        type="button"
                    >Reject Cookies</button>
                </div>
            </main>            
        </aside>
    );
};

export default GDPRModal;