import "./OverlayCoupon.scss";
import { useState } from 'react';
import UberEatsLogo from '../../assets/logos/uber_eats_logo_large.png';
import CloseIcon from '../../assets/icons/close-button.svg';
import { couponCode } from "../../data/couponCode";

const OverlayCoupon = ({ 
    startZoomOut,
    clearOverlayContent
}) => {
    const [ fadeOut, setFadeOut ] = useState(false);

    const handleClose = () => {
        setFadeOut(true);
        startZoomOut()
    };
    
    const handleFadeOut = e => {
        if (e.animationName === 'fade-out-overlay') clearOverlayContent();
    }

    return (
        <div className={`modal ${fadeOut ? 'fade-out--overlay' : ''}`} onAnimationEnd={handleFadeOut} >
            <header className="coupon__header">
                <div className="coupon__header-title-container">
                    <h1 className="coupon__header-title">
                        Get Your Vouch-ihuahua
                    </h1>
                </div>
                <p className="coupon__header-text coupon__header-text--bold">
                    “Discounts are clinically proven to improve flavour”
                </p>
                <p className="coupon__header-text">
                    - Paris Hilton, Chihuahua Wrangler
                </p>
                <button 
                    className="coupon__content-btn coupon__content-btn--hide-mob"
                    onClick={handleClose}
                >
                    <img src={CloseIcon} alt="close coupon" />
                </button>
            </header>
            <div className="coupon__content">
                <div className="coupon__content-segment">
                    <img className="coupon__content-logo" src={UberEatsLogo} alt="Uber Eats" />
                </div>
                <div className="coupon__content-segment">
                    <h2>
                        20% OFF VOUCHER
                    </h2>
                    <p>
                        Offer applies at all restaraunts while the promotion is active
                    </p>
                </div>
                <div className="coupon__content-segment">
                    <h2>
                        Use Code
                    </h2>
                    <p className="coupon__code">
                        { couponCode }
                    </p>
                    <p>
                        At checkout to get your discount!
                    </p>
                </div>
                <div className="coupon__content-segment coupon__content-segment--hide">
                    <button
                        className="coupon__content-btn"
                        onClick={handleClose}
                    >
                        <img src={CloseIcon} alt="close coupon" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OverlayCoupon;