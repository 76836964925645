import POI from '../POI/POI';

const POIs = ({
    poiData,
    setShowOverlay,
    setOverlayContent,
    zoomToFromPoi,
    state
}) => {    
    return (
        poiData.map((poi, i) => {
            return (
                <POI
                    key={i} 
                    poi={poi}
                    setShowOverlay={setShowOverlay}
                    setOverlayContent={setOverlayContent}
                    zoomToFromPoi={zoomToFromPoi}
                    state={state} // Consent for Analytics
                />
            )
        })
    )
};

export default POIs;