import './OverlayPOI.scss';
import { useRef, useState } from 'react';
import CloseIconWhite from '../../assets/icons/close-button.svg';
import PlayIcon from '../../assets/icons/play-button.png';
import { CDN_URL } from '../../utilities/URLS';

const OverlayPOI = ({ 
    overlayContent, 
    startZoomOut,
    clearOverlayContent,
    muted,
    playBackgroundAudio,
    pauseBackgroundAudio
}) => {   
    const {
        author,
        title, 
        quote,
        media,
        audioClip = new Audio()
    } = overlayContent.overlayContent;
    
    // CDN Urls
    const VIDEO_URL = `${CDN_URL}/videos/${media.videoSrc}`;
    const AUDIO_CLIP_URL = `${CDN_URL}/audio-clips/${media.audioClipSrc}`;
    
    const [ fadeOut, setFadeOut ] = useState(false);
    const [ playing, setPlaying ] = useState(false);
    const videoRef = useRef(null);

    if (media.audioClipSrc) {
        audioClip.src = AUDIO_CLIP_URL;
        audioClip.volume = 0.3;
    }

    const handleClose = () => {
        videoRef.current.pause();
        if (!muted && media.audioClipSrc) audioClip.play();
        setFadeOut(true);
        startZoomOut();
    };

    const handleFadeOut = e => { if (e.animationName === 'fade-out-overlay') clearOverlayContent() };

    const handlePlayVideo = () => setPlaying(true);

    const handleAnimationEnd = () => videoRef.current.play();

    return (
        <div
            className={`overlay ${fadeOut ? 'fade-out--overlay' : ''}`}
            onAnimationEnd={handleFadeOut}
        >
            <div className='overlay__content overlay__content--text'>
                <h1 className='overlay__title'>{ title }</h1>
                <p className='overlay__quote'>{ quote }</p>
                <p className='overlay__author'>- { author }</p>
                <button
                    className="overlay__button"
                    onClick={handleClose}
                    type="button"
                >
                    <img src={ CloseIconWhite } alt="close overlay" />
                </button>
            </div>
            <div className="overlay__content overlay__content--video overlay__content--video-background">
                <div className='overlay__video-wrapper'>
                    <video
                        ref={videoRef}
                        controls={playing}
                        playsInline
                        muted={muted}
                        autoPlay={playing}
                        // Connect Background Audio To Video Playing State
                        onPlay={pauseBackgroundAudio}
                        onPause={playBackgroundAudio}
                        onEnded={playBackgroundAudio}
                    >
                        <source
                            src={VIDEO_URL}
                            type="video/mp4"
                        />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <button
                        className={`overlay__video-play-btn ${playing ? 'overlay__video-play-btn--fade-out' : ''}`}
                        onClick={handlePlayVideo}
                        onAnimationEnd={handleAnimationEnd}
                    >
                        <img src={PlayIcon} alt="Play video" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OverlayPOI;