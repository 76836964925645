import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import axios from 'axios';

import CookiePopup from "../components/CookiePopup/CookiePopup";
import GDPRModal from "../components/GDPRModal/GDPRModal";

import { gdprCompliantCountries } from '../data/consentCountries';

  const COOKIE_NAME = "consent";
  
  const CookieConsentStateContext = createContext();
  const CookieConsentDispatchContext = createContext();

  function getCookie() {
    const regex = new RegExp(`(?:(?:^|.*;\\s*)${COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`)
    const cookie = document.cookie.replace(regex, "$1")
    return cookie.length ? JSON.parse(cookie) : undefined
  }

  let initialCookieValue = getCookie() || {
    isSet: 0,
    marketing: 0,
  };

  
  const CookieConsentProvider = ({ children }) => {
    const [popupIsOpen, setPopupIsOpen] = useState(!initialCookieValue.isSet);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [gdprPopup, setGdprPopup] = useState(true);

  
    const [state, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case "acceptCurrent":
          setPopupIsOpen(false);
          return {
            isSet: 1,
            marketing: 1,
          };
        case "declineAll":
          setPopupIsOpen(false);
          return {
            marketing: 0,
            isSet: 1,
          };
        case "showCookiePopup":
          setPopupIsOpen(true);
          return state;
        default:
          throw new Error();
      }
    }, initialCookieValue);
  
    // Update the cookie when state changes
    
    
    async function consentRequired() {
      try {
        const { data } = await axios.get(
          `https://extreme-ip-lookup.com/json/?key=dEShGFItdUxbde2W9mFw`
        );
        
        const gdprRequired = gdprCompliantCountries.includes(data.country);
                
        if (!gdprRequired) setGdprPopup(false);
      } catch (e) {
        console.error(e);
      }
    }

    useEffect(() => {
      document.cookie = `${COOKIE_NAME}=${JSON.stringify(state)}`;
    }, [state]);

    useEffect(() => {
      consentRequired();
    }, [])


    return (
      <CookieConsentStateContext.Provider value={{state, gdprPopup}}>
        <CookieConsentDispatchContext.Provider value={{dispatch, setShowPrivacyPolicy}}>
          {gdprPopup && popupIsOpen && <CookiePopup dispatch={dispatch} setShowPrivacyPolicy={setShowPrivacyPolicy} />}
          { gdprPopup && showPrivacyPolicy && <GDPRModal dispatch={dispatch} setShowPrivacyPolicy={setShowPrivacyPolicy} />}
          {children}
        </CookieConsentDispatchContext.Provider>
      </CookieConsentStateContext.Provider>
    );
  };
  
  function useCookieConsentState() {
    const context = useContext(CookieConsentStateContext);
    if (context === undefined) {
      throw new Error("useCookieConsentState must be used within CookieProvider");
    }
    return context;
  }
  
  function useCookieConsentDispatch() {
    const context = useContext(CookieConsentDispatchContext);
    if (context === undefined) {
      throw new Error(
        "useCookieConsentDispatch must be used within CookieProvider"
      );
    }
    return context;
  }
  
  export {
    CookieConsentProvider,
    useCookieConsentState,
    useCookieConsentDispatch,
  };