import './App.scss';

import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useCookieConsentState} from './contexts/CookieConsentContext';

import Header from './components/Header/Header';
import MapCanvas from './components/MapCanvas/MapCanvas';
import PoiCounter from './components/PoiCounter/PoiCounter';
import Instructions from './components/Instructions/Instructions';
import Splashscreen from './components/Splashscreen/Splashscreen';
import OverlayController from './components/OverlayController/OverlayController';

import { pointsOfInterest } from './data/pointsData';
import { fadeInAudio } from './utilities/functions';

import BackgroundAudioFile from './assets/audio/background-audio.m4a';
import SplashscreenImg from './assets/images/splashscreen.png';
import InstructionsImg from './assets/images/instructions.png'

import { usePageVisibility } from './hooks/usePageVisibility';

function App() {
  // Manage Content Overlays
  const [ showOverlay, setShowOverlay ] = useState(false);
  const [ overlayContent, setOverlayContent ] = useState(null);
  const [ poiData, setPoiData ] = useState(pointsOfInterest);

  // Manage Instructional Overlays
  const [ showInstructions, setShowInstructions ] = useState(false);
  const [ showSplashscreen, setShowSplashscreen ] = useState(true);

  // Start Animation
  const [ startAnimation, setStartAnimation ] = useState(false);

  // Zoom In / Out Functionality
  const [ zoomIn, setZoomIn ] = useState(false);
  const [ zoomOut, setZoomOut ] = useState(false);

  // Manage Audio
  const [ muted, setMuted ] = useState(false);
  const visible = usePageVisibility();
  const backgroundAudioRef = useRef(new Audio());
  const userHasInteracted = useRef(false);

  const { state, gdprPopup } = useCookieConsentState();

  // Analytics
  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    if (!gdprPopup || state.marketing === 1) {
      const GT_ID = 'GTM-WKQJB8C';
      TagManager.initialize({ gtmId : GT_ID })
    }
    }, [state.marketing, gdprPopup])

  // Preload Assets
  useEffect(() => {
    new Image().src = SplashscreenImg;
    new Image().src = InstructionsImg;
  }, []);

  // Background Audio
  // Init
  useEffect(() => {
    backgroundAudioRef.current.src = BackgroundAudioFile;
    backgroundAudioRef.current.loop = true;
  }, []);

  // Turn off Audio if screen not visible
  if (userHasInteracted.current && !muted) {
    if (!visible) {
      backgroundAudioRef.current.pause();
    } else {
      backgroundAudioRef.current.play();
    }
  }

  const updatePoiData = data => { 
    const updatedData = poiData.map(poi => {
      if (poi.title === data.title) poi.completed = true;
      return poi;
    });

    setPoiData(updatedData);
  };

  const handleStartAnimation = () => {
    setShowSplashscreen(false);
    setShowInstructions(true);
  };

  const playBackgroundAudio = () => {
    if (muted) return;
    if (userHasInteracted.current === false) userHasInteracted.current = true;

    fadeInAudio(
      backgroundAudioRef.current,
      0.3
    );
  }

  const pauseBackgroundAudio = () => {
    backgroundAudioRef.current.pause();
  };

    const toggleMuteAudio = () => {
    if (muted) return backgroundAudioRef.current.play();

    backgroundAudioRef.current.pause()
  }

  // Zoom In / Out
  const startZoomOut = () => {
    setZoomIn(false);
    setZoomOut(true);
  };

  // Time for zoom out before controls unlock
  if (zoomOut) {
    setTimeout(() => {
      setZoomOut(false);
    }, 2000);
  }

  // Handle Overlay Content
  const clearOverlayContent = () => {
    setShowOverlay(false);
    updatePoiData(overlayContent);
    setOverlayContent(null);
  };
  
  return (
    <>
      <Header
        setShowInstructions={setShowInstructions}
        showSplashscreen={showSplashscreen}
      />
      <main id="canvas" className='app-content'>
        <MapCanvas
          poiData={poiData} 
          updatePoiData={updatePoiData}
          setShowOverlay={setShowOverlay}
          zoomIn={zoomIn}
          setZoomIn={setZoomIn}
          zoomOut={zoomOut}
          setOverlayContent={setOverlayContent}
          setStartAnimation={setStartAnimation}
          showInstructions={showInstructions}
          showSplashscreen={showSplashscreen}
        />
        { 
          poiData&& 
          <PoiCounter
            poiData={poiData}
            muted={muted}
            setMuted={setMuted} // Restructure this to include in toggle mute audio
            toggleMuteAudio={toggleMuteAudio} 
          /> 
        }        
        { 
          showInstructions &&
          <Instructions
            setShowInstructions={setShowInstructions}
            playBackgroundAudio={playBackgroundAudio} 
            muted={muted}
          /> 
        }
        {
          showSplashscreen &&
          <Splashscreen 
            startAnimation={startAnimation}
            handleStartAnimation={handleStartAnimation}
          />
        }
        {
          showOverlay && 
          <OverlayController
            overlayContent={overlayContent}
            startZoomOut={startZoomOut}
            clearOverlayContent={clearOverlayContent}
            muted={muted}
            playBackgroundAudio={playBackgroundAudio}
            pauseBackgroundAudio={pauseBackgroundAudio}
          />
        }
      </main>
    </>
  );
}

export default App;