import './Instructions.scss';
import InstructionsImg from '../../assets/images/instructions.png';
import IntructionsCloseIcon from '../../assets/icons/instructions-close.png';

const Instructions = ({
    setShowInstructions, 
    playBackgroundAudio,
    muted
}) => {

    const handleCloseModal = e => {
        e.stopPropagation();

        if (e.target.className === 'instructions__img') return;
        
        if (!muted) playBackgroundAudio();

        setShowInstructions(false);
    }

    return (
        <div 
            className='instructions' 
            onClick={handleCloseModal}
        >
            <div className='instructions__container'>
                <button
                    className='instructions__btn'
                    onClick={handleCloseModal}
                    type="button"
                >
                    <img
                        src={IntructionsCloseIcon}
                        alt="close instructions"
                    />

                </button>
                <img className='instructions__img' src={InstructionsImg} alt="" />
            </div>
        </div>
    );
};

export default Instructions;