import './CookiePopup.scss';

const CookiePopup = ({ dispatch, setShowPrivacyPolicy }) => {
  return (
    <section className='cookie-banner'>
      <p className='cookie-banner__text'>
        This site uses cookies to improve userbility and measure traffic. For more details, see our <button className='cookie-banner__link' onClick={() => setShowPrivacyPolicy(true)}>cookie policy</button>.
      </p>
      <button className='cookie-banner__btn' onClick={() => dispatch({ type: "acceptCurrent" })}>I understand</button>
    </section>
)};

export default CookiePopup;