import './Menu.scss';
import { PRIVACY_POLICY_URL, UBER_EATS_URL } from '../../utilities/URLS';
import { useCookieConsentDispatch, useCookieConsentState } from '../../contexts/CookieConsentContext';

const Menu = ({ showMenu, setShowInstructions, setShowMenu }) => {
    const { gdprPopup } = useCookieConsentState();
    const { setShowPrivacyPolicy } = useCookieConsentDispatch();

    const handleShowInstructions = () => {
        setShowMenu(false);
        setShowInstructions(true);
    };

    return (
        <div className={`menu ${showMenu ? 'menu--visible' : 'menu--hidden'}`}>
            <div className='menu__container'>
                <div className='menu__links-container'>
                    <ul className='menu__links'>
                        <li>
                            <button
                                className='menu__link'
                                type="button"
                                onClick={handleShowInstructions}
                            >Instructions</button>
                        </li>
                        <li>
                            <a 
                                className='menu__link' 
                                href={PRIVACY_POLICY_URL}
                                target="_blank"
                                rel="noreferrer"
                            >Privacy Policy</a>
                        </li>
                        {
                            gdprPopup &&
                            <li>
                                <button 
                                    className='menu__link'
                                    onClick={() => setShowPrivacyPolicy(true)} 
                                    >Cookie Policy</button>
                            </li>
                        }
                        <li>
                            <a 
                                className='menu__link' 
                                href={UBER_EATS_URL}
                                target="_blank"
                                rel="noreferrer"
                            >Uber eats</a>
                        </li>
                    </ul>
                </div>
                <footer className='menu__footer'>
                    <p className='menu__footnote'>© 2022 Uber Technologies Inc.</p>
                </footer>
            </div>
        </div>
    );
};

export default Menu;