import { BackSide } from "three";

const Background = ({ color }) => {
    return (
        <mesh scale={100}>
            <boxBufferGeometry />
            <meshBasicMaterial
                side={BackSide} 
                color={color}
            />
        </mesh>
    );
};

export default Background;