import './OverlayFilter.scss';
import { useState  } from 'react';
import CloseIconWhite from '../../assets/icons/close-button.svg';
import InstagramLogo from '../../assets/logos/instagram.png';

const OverlayFilter = ({ 
    overlayContent, 
    startZoomOut,
    clearOverlayContent
}) => {
    const [ fadeOut, setFadeOut ] = useState(false);

    const { 
        title, 
        quote, 
        author, 
        media
    } = overlayContent.overlayContent;

    const handleClose = () => {
        setFadeOut(true);
        startZoomOut();
    };

    const handleFadeOut = e =>  { if (e.animationName === 'fade-out-overlay') clearOverlayContent() };

    return (
        <div
            className={`overlay ${fadeOut ? 'fade-out--overlay' : ''}`}
            onAnimationEnd={handleFadeOut}
        >
            <div className='overlay__content overlay__content--text'>
                <h1 className='overlay__title'>{ title }</h1>
                <q className='overlay__quote'>{ quote }</q>
                <p className='overlay__author'>{ author }</p>
                <button
                    className="overlay__button"
                    onClick={handleClose}
                    type="button"
                >
                    <img src={ CloseIconWhite } alt="close overlay" />
                </button>
            </div>
            <div
                className="overlay__content overlay-filter__content overlay-filter__content--filter-background"
            >
                <div className='overlay-filter__qr-wrapper'>
                    <img
                         src={InstagramLogo} 
                         alt="instagram"
                     />
                     <p className='overlay-filter__text overlay-filter__mob-hide'>Scan the QR Code to view this AR lense.</p>
                     <img
                        className='overlay-filter__mob-hide'
                        src={media.qrCodeSRC}
                        alt="QR Code"
                    />
                </div>
                <footer className='overlay-filter__footer'>
                    <a
                        className='overlay-filter__text overlay-filter__redirect  desktop-hide' target="instagram"
                        href={media.filterURL}
                    >
                        View filter on Instagram
                    </a>
                    <p className='overlay-filter__text desktop-show'>
                        Or find us on the @UberEats Instagram
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default OverlayFilter;