import './POI.scss';
import { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Html } from "@react-three/drei";
import { Vector3 } from 'three';
import { clamp, convertToDecimal, isMobileDevice } from '../../utilities/functions';
import { pushPOIToDataLayer } from '../../analytics/analytics';

const POI = (props) => {
    const { 
        poi, 
        setShowOverlay, 
        setOverlayContent,
        zoomToFromPoi,
        screenWidth = "767px", // Default Tablet width - 1px
        state
    } = props;
    
    const poiRef = useRef(null);
    const animationRef = useRef(null)
    const { camera } = useThree();
    
    const [ x, y, z ] = poi.position;

    const handleClick = poi => {       
        const focusPosition = new Vector3();

        // Set Zoom In position based on device size
        isMobileDevice(screenWidth) ? 
            focusPosition.set(
                poi.position[0],
                poi.position[1],
                poi.position[2],
            ) :
            focusPosition.set(
                poi.focus[0],
                poi.focus[1],
                poi.focus[2],
            );

        zoomToFromPoi( camera.position, focusPosition ); // Set POI and Camera Return to Position for lerping / zoom

        setOverlayContent(poi)
        setTimeout(() => setShowOverlay(true), 1250) // Delay overlay appearing when zooming in.

        if (state?.marketing === 1) {
            pushPOIToDataLayer(poi.title); // Push selected POI to DataLayer
        } 
    };

    useFrame(() => {
        if (poiRef.current) {
            const poiSizeOffset = clamp(
                convertToDecimal(camera.position.y, 1, 10),
                0.75,
                2
            );
            poiRef.current.style.transform = `scale(${poiSizeOffset})`;
        }
    });


    
    return (
        <Html
            ref={poiRef}
            as='div'
            distanceFactor={5}
            prepend
            position={[x, 3, z]}
            {...props}
        >
            <div 
                ref={animationRef}
                id={poi.title}
                className={`poi ${poi.completed ? 'poi--complete' : ''}`}
                onClick={() => handleClick(poi)}
            >
                <img
                    className={
                        `poi__img 
                        ${poi.completed ? 'poi__img--complete' : ''}`
                    }
                    src={`/poi-icons/${poi.imgUrl}`}
                    alt={poi.title}
                />
            </div>
        </Html>
    );
};

export default POI;