export const distanceBetweenCoords = (point1, point2) => {
    return Math.sqrt((point2.clientX - point1.clientX) ** 2 + (point2.clientY - point1.clientY) ** 2);
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const convertToDecimal = (currentHeight, minHeight, maxHeight) => {
  const range = maxHeight - minHeight;
  const heightIncrease = currentHeight - minHeight;

  return heightIncrease / range;
};

export const fadeInAudio = (audio, end, milliSecs = 400) => {
  audio.volume = 0;
  audio.play();

  setInterval(() => {
    if (audio.volume >= end) {
      return clearInterval(fadeInAudio);
    }
    audio.volume += 0.1;
  }, milliSecs);
}

export const isMobileDevice = screenWidth => window.matchMedia(`only screen and (max-width: ${screenWidth})`).matches;
