import { instagramFilter } from "./instagramFilters";

export const defaultHeight = 5;

export const pointsOfInterest = [
  /** IG Filters */
  {
      title : 'ig filter: chihuahua',
      imgUrl : 'instagram.png',
      position: [-18, defaultHeight, -3],
      focus: [-18, defaultHeight, -3],
      completed : false,
      overlayContent : {
          title : 'Pocket Chihuahua Instagram Lens',
          type : 'filter',
          quote : 'Chihuahua\'s are so hot right now',
          author : 'Paris Hilton, New Zookeeper',
          media : {
              filterURL : instagramFilter.chihuahua,
              qrCodeSRC : '/qr-codes/Hello_Social_Uber_Eats_Chihuahua.svg'
          }
      }
  },
  {
      title : 'ig filter: crocodile',
      imgUrl : 'instagram.png',
      position : [10.5, defaultHeight, -3.5],
      focus: [5, defaultHeight, -4.5],
      completed : false,
      overlayContent : {
          title : 'Pocket Crocodile Instagram Lens',
          type : 'filter',
          quote : 'Crocodile\'s are so hot right now',
          author : 'Paris Hilton, New Zookeeper',
          media : {
              filterURL : instagramFilter.crocodile,
              qrCodeSRC : '/qr-codes/Hello_Social_Uber_Eats_Crocodile.svg'
          }
      }
  },
  /** Coupon */
  // {
  //     title : 'coupon',
  //     imgUrl : 'coupon.png',
  //     position : [2.75, defaultHeight, 10],
  //     focus : [0, defaultHeight, 0],
  //     completed : false,
  //     overlayContent : {
  //         title : 'Chihuahua Enclosure',
  //         type : 'coupon',
  //         quote : 'Chihuahua\'s are so hot right now',
  //         author : 'Paris Hilton, New Zookeeper',
  //         media : {
  //             couponSrc : ''
  //         }
  //     }
  // },
  /** Enclosure POI's */
  {
    title: "chihuahua",
    imgUrl: "chihuahua.png",
    position: [-12.5, defaultHeight, -4],
    focus: [-15, defaultHeight, -3],
    completed: false,
    overlayContent: {
      title: "The Chihuahuas",
      type: "video",
      quote: "Here we have the sna-err, chihuahuas!",
      author: "Terri Irwin, Mother Zookeeper",
      media: {
        videoSrc: "Chihuahua.mp4",
        audioClipSrc: "Paris_CheckThisOut.mp3",
      },
    },
  },
  {
    title: "dingo",
    imgUrl: "dingo.png",
    position: [-5, defaultHeight, -14],
    focus: [-7, defaultHeight, -8],
    completed: false,
    overlayContent: {
      title: "The Dingoes",
      type: "video",
      quote: "Diamond Baby, say hi to your new BFFs!",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "Dingo.mp4",
        audioClipSrc: "Bindi_Exploring_02.mp3",
      },
    },
  },
  {
    title: "elephant",
    imgUrl: "elephant.png",
    position: [-11, defaultHeight, 6],
    focus: [-11, defaultHeight, 7],
    completed: false,
    overlayContent: {
      title: "The Elephants",
      type: "video",
      quote: "I bet these guys were vegan before it was hot.",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "Elephant.mp4",
        audioClipSrc: "Bindi_Exploring_01.mp3",
      },
    },
  },
  {
    title: "kookaburra",
    imgUrl: "kookaburra.png",
    position: [2.25, defaultHeight, 9],
    focus: [0, defaultHeight, 15],
    completed: false,
    overlayContent: {
      title: "The Kookaburras",
      type: "video",
      quote: "You might recognise these guys from their famous laugh!",
      author: "Bindi Irwin, Zookeeper",
      media: {
        videoSrc: "Kookaburra.mp4",
        audioClipSrc: "Paris_OverHere.mp3",
      },
    },
  },
  {
    title: "crocodile",
    imgUrl: "crocodile.png",
    position: [8, defaultHeight, -6],
    focus: [5, defaultHeight, -4.5],
    completed: false,
    overlayContent: {
      title: "The Crocodiles",
      type: "video",
      quote: "Fact! These guys are related to dinosaurs.",
      author: "Rob Irwin, Zookeeper",
      media: {
        videoSrc: "Croc.mp4",
        audioClipSrc: "Rob_WhereNext.mp3",
      },
    },
  },
  {
    title: "kangaroo",
    imgUrl: "kangaroo.png",
    position: [-3.75, defaultHeight, -1.5],
    focus: [-6, defaultHeight, 0],
    completed: false,
    overlayContent: {
      title: "The Kangaroos",
      type: "video",
      quote: "Cute, but I prefer the term 'bouncy mouse'.",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "Kangaroo.mp4",
        audioClipSrc: "Rob_WhereNext.mp3",
      },
    },
  },
  /** Non Enclosure POI's */
  // Round 1
  {
    title: "ue_pool_day_crocodiles",
    imgUrl: "pool_day.png",
    position: [4, defaultHeight, -2.5],
    focus: [5, defaultHeight, -4.5],
    completed: false,
    overlayContent: {
      title: "The Crocodiles",
      type: "video",
      quote: "It's called multitasking, guys. Look it up.",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "UE_PoolDay.mp4",
        audioClipSrc: "Rob_WhereNext.mp3",
      },
    },
  },
  // Round 2
  // {
  //   title: "ue_surprise_15",
  //   imgUrl: "surprise.png",
  //   position: [4, defaultHeight, -2.5],
  //   focus: [5, defaultHeight, -4.5],
  //   completed: false,
  //   overlayContent: {
  //     title: "",
  //     type: "video",
  //     quote: "",
  //     author: "Paris Hilton, New Zookeeper",
  //     media: {
  //       videoSrc: "Surprise_15.mp4",
  //       audioClipSrc: "Rob_WhereNext.mp3",
  //     },
  //   },
  // },
  {
    title: "iguana",
    imgUrl: "iguana.png",
    position: [-6.5, defaultHeight, 12],
    focus: [-6.5, defaultHeight, 12],
    completed: false,
    overlayContent: {
      title: "The Goanas",
      type: "video",
      quote: "Um. A snake with legs?",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "Iguana.mp4",
        audioClipSrc: "Paris_CheckThisOut.mp3",
      },
    },
  },
  {
    title: "koala",
    imgUrl: "koala.png",
    position: [10.5, defaultHeight, 5.5],
    focus: [5, defaultHeight, 8],
    completed: false,
    overlayContent: {
      title: "The Koalas",
      type: "video",
      quote: "So. Chilled. Loves it.",
      author: "Paris Hilton, New Zookeeper",
      media: {
        videoSrc: "Koala.mp4",
        audioClipSrc: "Bindi_Exploring_02.mp3",
      },
    },
  }
];
