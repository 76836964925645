import'./PoiCounter.scss';
import { useState } from 'react';
import PoiImg from '../../assets/icons/poi_icon.png';
import MutedIcon from "../../assets/icons/audio_muted.png";
import UnMutedIcon from '../../assets/icons/audio_unmuted.png';

const PoiCounter = ({ poiData, muted, setMuted, toggleMuteAudio }) => {
    const [ expandCounter, setExpandCounter ] = useState(false);

    const handleMute = e => {
        e.stopPropagation();
        toggleMuteAudio();
        setMuted(!muted);
    }

    const completed = poiData.filter(data => data.completed).length;
    
    return (
        <aside
            className={`poi-counter ${expandCounter ? 'poi-counter--visible' : 'poi-counter--hidden'}`}
            onClick={() => setExpandCounter(!expandCounter)}
        >
            <div className='poi-counter__content'>
                <div className='poi-counter__img-container'>
                    <img
                        src={PoiImg}
                        alt={`you have visited ${completed} points of interest out of ${poiData.length}`} 
                    />
                </div>
                <p className='poi-counter__text'><span className='poi-counter__text--hightlight'>{completed}</span> / {poiData.length}</p>
            </div>
            <button
                className='poi-counter__content'
                type="button"
                onClick={handleMute}
            >
                <div className='poi-counter__img-container'>
                    <img 
                        src={muted ? MutedIcon : UnMutedIcon } 
                        alt="mute button" 
                    />
                </div>
                <p className='poi-counter__text'>{muted ? "Unmute" : "Mute"}</p>
            </button>
        </aside>
    );
};

export default PoiCounter;