import './Header.scss';
import { useState } from 'react';
import Menu from '../Menu/Menu';
import MenuIcon from '../../assets/icons/menu_icon.png';
import Logo from '../../assets/logos/header_logo_mob.png';
import LogoLarge from '../../assets/logos/header_logo_large.png';


const Header = ({ setShowInstructions, showSplashscreen }) => {
    const [ showMenu, setShowMenu ] = useState(false);

    return (
        <>
        <header className="header">
            <div className="header__content">
                <div className='header__container'>
                    <button 
                        className={`header__btn ${showMenu ? "header__btn--close" : "header__btn--open" }`}
                        type="button"
                        disabled={showSplashscreen}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <img src={MenuIcon} alt="website navigation menu" />
                    </button>
                </div>
                <div className='header__container header__container-logo'>
                    <img 
                        className='header__logo' 
                        src={Logo} 
                        srcSet={`${Logo} 248w, ${LogoLarge} 681w`}
                        alt="Paris Hilton & The Irwin's Australia Zoo Adventure" 
                    />
                </div>
            </div>
        </header>
        <Menu showMenu={showMenu} setShowInstructions={setShowInstructions} setShowMenu={setShowMenu} />
        </>
    );
};

export default Header;