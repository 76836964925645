import './MapCanvas.scss';
import { useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { ScrollControls, Scroll } from '@react-three/drei';
import Controller from '../../components-three-js/Controller/Controller';
import Loader from '../../components-three-js/Loader/Loader';
import MapV9 from '../../components-three-js/Map/MapV9';
import POIs from '../../components-three-js/POIs/POIs';
import Background from '../../components-three-js/Background/Background';
import { useCookieConsentState } from '../../contexts/CookieConsentContext';

const MapCanvas = ({ 
    poiData, 
    setShowOverlay, 
    zoomIn,
    setZoomIn,
    zoomOut,
    setOverlayContent,
    setStartAnimation,
    showInstructions,
    showSplashscreen
}) => {
    // Deffault Variables
    const cameraZOffset = 5 // Camera's default distance from target

    const [ focus, setFocus ] = useState({});
    const [ targetOrigin, setTargetOrigin ] = useState({});

    const { state } = useCookieConsentState();

    const zoomToFromPoi = ( cameraPosition, focusPosition ) => {
        if ( !zoomOut && zoomIn ) return; 

        // Set Camera Return To position
        if ( !zoomIn && !zoomOut ) {
            setTargetOrigin({
                x : cameraPosition.x,
                y : cameraPosition.y,
                z : cameraPosition.z - cameraZOffset
            })
        }

        // Set Camera Zoom To Position
        setFocus({
            x : focusPosition.x,
            y : focusPosition.y,
            z : focusPosition.z
        })

        setZoomIn(true);
    }

    return (
        <div className='map-canvas'>
            <Canvas>
                <ScrollControls>
                    <Scroll>
                        <Controller
                            cameraZOffset={cameraZOffset}
                            zoomIn={zoomIn}
                            zoomOut={zoomOut}
                            focus={focus}
                            targetOrigin={targetOrigin}
                            showInstructions={showInstructions}
                            showSplashscreen={showSplashscreen}
                        />
                        <Suspense fallback={ <Loader setStartAnimation={setStartAnimation} /> }>
                            <MapV9 rotation={[ 0, Math.PI / 4, 0 ]} />
                            <POIs
                                poiData={poiData}
                                setShowOverlay={setShowOverlay}
                                setOverlayContent={setOverlayContent}
                                zoomToFromPoi={zoomToFromPoi}
                                state={state}
                            />
                            <Background color={"#4EC78C"} />
                        </Suspense>
                    </Scroll>
                </ScrollControls>
            </Canvas>
        </div>
    );
};

export default MapCanvas;