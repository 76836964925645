import './Loader.scss';
import { 
    // Html, 
    useProgress 
} from "@react-three/drei";
// import SplashscreenImg from '../../assets/images/splashscreen.png';

const Loader = ({ setStartAnimation }) => {

    const { progress } = useProgress();

    if (progress === 100) setStartAnimation(true);

    // return (
    //     <Html
    //         as="div"
    //         wrapperClass='loader'
    //     >
    //         <div className='loader__img-container'>
    //             <img src={SplashscreenImg} alt="Paris Hilton and the Irwins' Australia Zoo Adventure" />
    //         </div>
    //     </Html>
    // );
};

export default Loader;