import "./Splashscreen.scss";
import SplashscreenImg from '../../assets/images/splashscreen.png';

const Splashscreen = ({
    startAnimation,
    handleStartAnimation
}) => {

    return (
        <>
        <div 
            className={`splashscreen splashscreen--img ${startAnimation ? "splashscreen__fade-out" : ""}`}
            onAnimationEnd={handleStartAnimation}
        >
            <img src={SplashscreenImg} alt="Paris Hilton & The Irwins' Australia Zoon Adventure" />
        </div>
        </>  
    );
};

export default Splashscreen;