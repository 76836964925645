/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useEffect } from "react";

export default function MapV9(props) {
  const modelRef = useRef();
  const { nodes, materials, animations } = useGLTF("/models/UberEatsMap_v9.glb");
  const { actions } = useAnimations(animations, modelRef);

  useEffect(() => {
    // Play Animations
    Object.keys( actions ).forEach( action => {
      actions[ action ].play();
    })
  }, [ actions ]);

  useEffect(() => {
    // Model
    modelRef.current.frustumCulled = false;

    // Animals
    modelRef.current.children.forEach( animals => {
      animals.frustumCulled = false;

      animals.children.forEach( animal => {
        animal.frustumCulled = false;
        
        animal.children.forEach( mesh => {
          mesh.frustumCulled = false;
        })
      })
    })
  }, []);

  return (
    <group ref={modelRef} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Bird_1"
          position={[-7.42, 4.24, 9.34]}
          rotation={[0, 0.84, 0]}
        >
          <primitive object={nodes.Root} />
          <skinnedMesh
            name="Bird_1_Model"
            geometry={nodes.Bird_1_Model.geometry}
            material={nodes.Bird_1_Model.material}
            skeleton={nodes.Bird_1_Model.skeleton}
          />
        </group>
        <group
          name="Bird_2"
          position={[-7.59, 6.31, 13.71]}
          rotation={[0, -0.47, 0]}
        >
          <primitive object={nodes.Root_4} />
          <skinnedMesh
            name="Bird_2_Model"
            geometry={nodes.Bird_2_Model.geometry}
            material={nodes.Bird_2_Model.material}
            skeleton={nodes.Bird_2_Model.skeleton}
          />
        </group>
        <group
          name="Bird_3"
          position={[-5.08, 6.86, 9.66]}
          rotation={[0, 0.02, 0]}
        >
          <primitive object={nodes.Root_1} />
          <skinnedMesh
            name="Bird_3_Model"
            geometry={nodes.Bird_3_Model.geometry}
            material={nodes.Bird_3_Model.material}
            skeleton={nodes.Bird_3_Model.skeleton}
          />
        </group>
        <group
          name="ToyCroc_1"
          position={[11.45, 1.05, 1.3]}
          rotation={[0, -0.42, 0]}
        >
          <primitive object={nodes.Root_2} />
          <skinnedMesh
            name="ToyCroc_1_Model"
            geometry={nodes.ToyCroc_1_Model.geometry}
            material={nodes.ToyCroc_1_Model.material}
            skeleton={nodes.ToyCroc_1_Model.skeleton}
          />
        </group>
        <group
          name="ToyCroc_2"
          position={[11.33, 1.06, 5.65]}
          rotation={[Math.PI, -0.6, Math.PI]}
        >
          <primitive object={nodes.Root_3} />
          <skinnedMesh
            name="ToyCroc_2_Model"
            geometry={nodes.ToyCroc_2_Model.geometry}
            material={nodes.ToyCroc_2_Model.material}
            skeleton={nodes.ToyCroc_2_Model.skeleton}
          />
        </group>
        <group
          name="Chihuahua"
          position={[-7.24, 1.01, -9.98]}
          rotation={[0, -0.4, 0]}
          scale={[0.4, 0.4, 0.4]}
        >
          <primitive object={nodes.root} />
          <skinnedMesh
            name="Chihuahua_MESH"
            geometry={nodes.Chihuahua_MESH.geometry}
            material={nodes.Chihuahua_MESH.material}
            skeleton={nodes.Chihuahua_MESH.skeleton}
          />
        </group>
        <group
          name="Dingo_1"
          position={[4.42, 0.37, -5.37]}
          rotation={[Math.PI, -0.11, Math.PI]}
          scale={[0.5, 0.5, 0.5]}
        >
          <primitive object={nodes.back_toe_r} />
          <primitive object={nodes.back_toe_l} />
          <primitive object={nodes.front_toe_l} />
          <primitive object={nodes.root_1} />
          <primitive object={nodes.front_toe_r} />
          <skinnedMesh
            name="Dingo_MESH"
            geometry={nodes.Dingo_MESH.geometry}
            material={nodes.Dingo_MESH.material}
            skeleton={nodes.Dingo_MESH.skeleton}
          />
        </group>
        <group
          name="Dingo_2"
          position={[8.66, 1.91, -12.51]}
          rotation={[0, -0.07, 0]}
          scale={[0.48, 0.48, 0.48]}
        >
          <primitive object={nodes.back_toe_r_1} />
          <primitive object={nodes.back_toe_l_1} />
          <primitive object={nodes.front_toe_l_1} />
          <primitive object={nodes.root_2} />
          <primitive object={nodes.front_toe_r_1} />
          <skinnedMesh
            name="Dingo_MESH001"
            geometry={nodes.Dingo_MESH001.geometry}
            material={nodes.Dingo_MESH001.material}
            skeleton={nodes.Dingo_MESH001.skeleton}
          />
        </group>
        <group
          name="Elephant_1"
          position={[-11.21, 0.41, -1.84]}
          rotation={[0, -1.19, 0]}
          scale={1.28}
        >
          <primitive object={nodes.Hips} />
          <skinnedMesh
            name="Elephant_MESH"
            geometry={nodes.Elephant_MESH.geometry}
            material={nodes.Elephant_MESH.material}
            skeleton={nodes.Elephant_MESH.skeleton}
          />
        </group>
        <group
          name="Elephant_2"
          position={[-14.86, 0.02, -0.42]}
          rotation={[0, 0.85, 0]}
          scale={1.21}
        >
          <primitive object={nodes.Hips_1} />
          <skinnedMesh
            name="Elephant_MESH001"
            geometry={nodes.Elephant_MESH001.geometry}
            material={nodes.Elephant_MESH001.material}
            skeleton={nodes.Elephant_MESH001.skeleton}
          />
        </group>
        <group
          name="Kangaroo_1"
          position={[-4, 0.36, -2.82]}
          rotation={[0, 0.29, 0]}
          scale={[0.48, 0.48, 0.48]}
        >
          <primitive object={nodes.handL} />
          <primitive object={nodes.toe001L} />
          <primitive object={nodes.handR} />
          <primitive object={nodes.Bone005} />
          <primitive object={nodes.spine} />
          <primitive object={nodes.toe001R} />
          <skinnedMesh
            name="Kangaroo_MESH001"
            geometry={nodes.Kangaroo_MESH001.geometry}
            material={nodes.Kangaroo_MESH001.material}
            skeleton={nodes.Kangaroo_MESH001.skeleton}
          />
        </group>
        <group
          name="Kangaroo_2"
          position={[-1.93, 0.36, -0.28]}
          rotation={[0, -1.33, 0]}
          scale={0.49}
        >
          <primitive object={nodes.handL_1} />
          <primitive object={nodes.toe001L_1} />
          <primitive object={nodes.handR_1} />
          <primitive object={nodes.Bone005_1} />
          <primitive object={nodes.spine_1} />
          <primitive object={nodes.toe001R_1} />
          <skinnedMesh
            name="Kangaroo_MESH002"
            geometry={nodes.Kangaroo_MESH002.geometry}
            material={nodes.Kangaroo_MESH002.material}
            skeleton={nodes.Kangaroo_MESH002.skeleton}
          />
        </group>
        <group name="UberEatsMAP">
          <mesh
            name="Map_Mesh001"
            geometry={nodes.Map_Mesh001.geometry}
            material={nodes.Map_Mesh001.material}
          />
          <mesh
            name="Map_Mesh001_1"
            geometry={nodes.Map_Mesh001_1.geometry}
            material={materials["Map-Large_MAT"]}
          />
          <mesh
            name="Map_Mesh001_2"
            geometry={nodes.Map_Mesh001_2.geometry}
            material={nodes.Map_Mesh001_2.material}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/models/UberEatsMap_v9.glb");
